import React from 'react'
import PropTypes from 'prop-types'
import { component, container } from '@k-mille/ui-components'

const DiffPlanned = container({ intl: 'revenues' })(({ messages }) => messages.diffPlanned)

const Revenue = ({
  messages,
  frequency,
  date,
  dates,
  planned,
  value,
  onRemove,
  onSubmit,
}) => (
  <tr>
    <td>
      {!dates.paid && (
        <button
          type="button"
          onClick={onRemove}
        >
          {messages.remove}
        </button>
      )}
    </td>
    <td>
      {frequency === 'MONTH' && messages.monthYearDate}
      {frequency === 'YEAR' && new Date(date).getUTCFullYear()}
    </td>
    <td>
      {dates.paid ? messages.planned : messages.value}
    </td>
    <td>
      {dates.paid
        ? (
          <>
            {messages.value}
            {' '}
            <DiffPlanned
              sign={value > planned ? '+' : ''}
              diff={value - planned}
            />
          </>
        )
        : (
          <form
            onSubmit={onSubmit}
          >
            <input
              type="number"
              name="value"
              defaultValue={value}
            />
            <button
              type="submit"
            >
              {messages.markAsPaid}
            </button>
          </form>
        )
      }
    </td>
  </tr>
)

Revenue.propTypes = {
  messages: PropTypes.object,
  frequency: PropTypes.string,
  date: PropTypes.number,
  dates: PropTypes.object,
  planned: PropTypes.number,
  value: PropTypes.number,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
}

Revenue.defaultProps = {
  messages: {},
  frequency: undefined,
  date: undefined,
  dates: {},
  planned: undefined,
  value: undefined,
  onRemove: undefined,
  onSubmit: undefined,
}

export default component()(Revenue)
