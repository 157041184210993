import { container } from '@k-mille/ui-components'
import { fromEvent } from 'from-form-submit'
import Component from './charge'

const mapStore = (store, { id }) => ({
  ...store.data.charges.get(id),
  onSubmit: (event) => {
    store.dispatch({
      type: '@@ui/PAY_CHARGE',
      payload: fromEvent(event, { id }),
    })
  },
})

export default container({
  intl: 'charges',
  mapStore,
})(Component)
