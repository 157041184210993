import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import Charge from './charge'

const Charges = ({
  className,
  messages,
  charges,
  onAdd,
}) => (
  <div className={className}>
    <ul>
      <li>
        <form onSubmit={onAdd}>
          <input name="date" type="date" />
          <input name="percentage" type="number" />

          <button type="submit">
            {messages.add}
          </button>
        </form>
      </li>
      {charges.map(id => (
        <Charge
          key={id}
          id={id}
        />
      ))}
    </ul>
  </div>
)

Charges.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.object,
  charges: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func,
}

Charges.defaultProps = {
  className: undefined,
  messages: {},
  charges: [],
  onAdd: undefined,
}

export default component()(Charges)
