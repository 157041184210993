import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'

const User = ({
  messages,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
  >
    {messages.fullname}
  </button>
)

User.propTypes = {
  messages: PropTypes.object,
  onClick: PropTypes.func,
}

User.defaultProps = {
  messages: {},
  onClick: undefined,
}

export default component()(User)
