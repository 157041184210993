import { container } from '@k-mille/ui-components'
import { fromEvent } from 'from-form-submit'
import Component from './charges'

const mapStore = store => ({
  charges: (store.data.humanResources.get(store.ui.selectedHuman.get()) || {}).charges,
  onAdd: (event) => {
    const data = fromEvent(event)
    data.percentage = (data.percentage || 0) / 100

    store.dispatch({ type: '@@ui/ADD_CHARGE', payload: data })
  },
})

export default container({
  intl: 'charges',
  mapStore,
})(Component)
