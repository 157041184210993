import React, { useState } from 'react'
import uuid from 'uuid/v4'
import PropTypes from 'prop-types'
import { component, container } from '@k-mille/ui-components'

const DiffPlanned = container({ intl: 'charges' })(({ messages }) => messages.diffPlanned)
const DiffPlannedPercentage = container({ intl: 'charges' })(({ messages }) => messages.diffPlannedPercentage)

const Charge = ({
  messages,
  date,
  total,
  planned,
  plannedPercentage,
  percentage,
  values,
  onSubmit,
}) => {
  const [innerValues, setInnerValues] = useState(values.map(value => ({ ...value, id: uuid() })))

  const onAddValue = () => {
    setInnerValues(old => [...old, { value: 0, description: '', id: uuid() }])
  }

  const onRemoveValue = id => () => {
    setInnerValues(old => old.filter(value => value.id !== id))
  }

  return (
    <li>
      <div>
        {new Date(date).getUTCFullYear()}
      </div>

      <table>
        <thead>
          <tr>
            <th>Planned</th>
            <th>Real</th>
            <th>Diff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {messages.planned}
            </td>
            <td>
              {messages.value}
            </td>
            <td>
              {planned && total !== planned && (
                <DiffPlanned
                  sign={total > planned ? '+' : ''}
                  diff={total - planned}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              {messages.plannedPercentage}
            </td>
            <td>
              {messages.percentage}
            </td>
            <td>
              {plannedPercentage && percentage !== plannedPercentage && (
                <DiffPlannedPercentage
                  sign={percentage > plannedPercentage ? '+' : ''}
                  diff={percentage - plannedPercentage}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Values</h2>
      <form
        onSubmit={onSubmit}
      >
        <table>
          <thead>
            <tr>
              <th>Value</th>
              <th>Description</th>
            </tr>
          </thead>

          <tbody>
            {innerValues.map(({ id, value, description }, index) => (
              <tr key={id}>
                <td>
                  <input
                    type="number"
                    name={`values.${index}.value`}
                    defaultValue={value}
                  />
                </td>
                <td>
                  <input
                    type="string"
                    name={`values.${index}.description`}
                    defaultValue={description}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={onRemoveValue(id)}
                  >
                    {messages.remove}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          type="button"
          onClick={onAddValue}
        >
          {messages.addValue}
        </button>
        <button
          type="submit"
        >
          {messages.submit}
        </button>
      </form>
    </li>
  )
}

Charge.propTypes = {
  messages: PropTypes.object,
  date: PropTypes.number,
  total: PropTypes.number,
  planned: PropTypes.number,
  plannedPercentage: PropTypes.number,
  percentage: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    description: PropTypes.string,
  })),
  onSubmit: PropTypes.func,
}

Charge.defaultProps = {
  messages: {},
  date: undefined,
  total: undefined,
  planned: undefined,
  plannedPercentage: undefined,
  percentage: undefined,
  values: [],
  onSubmit: undefined,
}

export default component()(Charge)
