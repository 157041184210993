import {
  queryHumanResources,
  queryUsers,
  mutationAddRevenue,
  mutationAddHuman,
  mutationRefundExpenses,
  mutationPayCharge,
  mutationPayRevenue,
  mutationAddCharge,
  mutationRemoveRevenue,
} from './humanResources.operations'

export const load = (action, store, { graphql }) => {
  graphql.query(`
    ${queryHumanResources()}
    ${queryUsers()}
  `)
}

export const set = ({ payload }, store) => {
  store.data.humanResources.set(payload.map(({ revenues, charges, ...human }) => ({
    ...human,
    revenues: revenues.map(({ id }) => id),
    charges: charges.map(({ id }) => id),
  })))

  payload.forEach(({ revenues, charges }) => {
    store.data.revenues.add(revenues)
    store.data.charges.add(charges)
  })

  if (payload.length > 0) {
    store.dispatch({
      type: '@@ui>HUMAN_SELECTED',
      payload: payload[0].id,
    })
  }
}

export const setSelectedHuman = ({ payload }, store) => {
  store.ui.selectedHuman.set(payload)
}

export const setUsers = ({ payload }, store) => {
  store.data.users.set(payload)
}

export const setExpenses = ({ payload }, store) => {
  store.data.expenses.set(payload)
}

export const filterUsers = (action, store) => {
  const humanResources = store.data.humanResources.getAsArray()
  const users = store.data.users.getAsArray()

  const usersId = humanResources.filter(({ userId }) => !!userId).map(({ userId }) => userId)

  store.ui.users.set(users.filter(({ id }) => !usersId.includes(id)))
}

export const createRevenue = ({ payload }, store, { graphql }) => {
  graphql.mutation(
    mutationAddRevenue(store.ui.selectedHuman.get()),
    {
      revenue: payload,
    },
  )
}

export const addRevenue = ({ payload }, store) => {
  const { humanId, ...revenue } = payload
  const human = store.data.humanResources.get(humanId)
  store.data.humanResources.add({
    ...human,
    revenues: [
      ...human.revenues,
      revenue.id,
    ],
  })
  store.data.revenues.add({ ...revenue, planned: revenue.value })
}

export const createHuman = ({ payload }, store, { graphql }) => {
  const { from = new Date(), ...human } = payload

  graphql.mutation(
    mutationAddHuman(),
    {
      human: {
        ...human,
        dates: {
          from,
        },
      },
    },
  )
}

export const addHuman = ({ payload }, store) => {
  store.data.humanResources.add(payload)
  store.ui.modalId.reset()
}

export const payRevenue = ({ payload }, store, { graphql }) => {
  graphql.mutation(mutationPayRevenue(), { revenue: payload })
}

export const refundExpenses = (action, store, { graphql }) => {
  const humanId = store.ui.selectedHuman.get()
  const ids = store.data.humanResources.get(humanId).expenses.map(({ id }) => id)
  graphql.mutation(mutationRefundExpenses(), { ids })
}

export const setRevenueAsPaid = ({ payload }, store) => {
  store.data.revenues.update(payload)
}

export const setTotalExpenseToRefund = (action, store) => {
  const human = store.data.humanResources.get(store.ui.selectedHuman.get()) || {}
  store.ui.totalExpenseToRefund.set((human.expenses || []).reduce(
    (acc, { prices }) => acc + prices.total,
    0,
  ))
}

export const createCharge = ({ payload }, store, { graphql }) => {
  graphql.mutation(
    mutationAddCharge(store.ui.selectedHuman.get()),
    {
      charge: payload,
    },
  )
}

export const addCharge = ({ payload }, store) => {
  const { humanId, ...charge } = payload
  const human = store.data.humanResources.get(humanId)
  store.data.humanResources.add({
    ...human,
    charges: [
      ...human.charges,
      charge.id,
    ],
  })
  store.data.charges.add(charge)
}

export const payCharge = ({ payload }, store, { graphql }) => {
  graphql.mutation(mutationPayCharge(), { charge: payload })
}

export const setChargeAsPaid = ({ payload }, store) => {
  store.data.charges.update(payload)
}

export const removeRevenue = ({ payload }, store, { graphql }) => {
  const humanId = store.ui.selectedHuman.get()
  const human = store.data.humanResources.get(humanId)

  store.data.revenues.remove(payload)
  store.data.humanResources.add({
    ...human,
    revenues: human.revenues.filter(id => id !== payload),
  })
  graphql.mutation(mutationRemoveRevenue(), { id: payload })
}
