import React from 'react'
import PropTypes from 'prop-types'
import { component, Tabs } from '@k-mille/ui-components'
import Add from './add'
import HumanItem from './humanItem'
import Revenues from './revenues'
import Charges from './charges'
import styles from './humanResources.styles'

const HumanResources = ({
  classes,
  messages,
  humans,
  totalExpensesToRefund,
  onSelectHuman,
  onRemove,
  onRefundClick,
  onAdd,
}) => (
  <div>
    <Add />
    <div>
      <select onChange={onSelectHuman}>
        {humans.map(human => (
          <HumanItem
            key={human.id}
            {...human}
          />
        ))}
      </select>

      <button
        type="button"
        onClick={onAdd}
      >
        {messages.add}
      </button>
      <button
        type="button"
        onClick={onRemove}
      >
        {messages.remove}
      </button>

      {!!totalExpensesToRefund && (
        <button
          type="button"
          onClick={onRefundClick}
        >
          {messages.toRefund}
        </button>
      )}
    </div>

    <Tabs titles={messages.titles} className={classes.tabs}>
      <Revenues />
      <Charges />
    </Tabs>
  </div>
)

HumanResources.propTypes = {
  messages: PropTypes.object,
  classes: PropTypes.object,
  humans: PropTypes.array,
  totalExpensesToRefund: PropTypes.number,
  onSelectHuman: PropTypes.func,
  onAdd: PropTypes.func,
  onRefundClick: PropTypes.func,
  onRemove: PropTypes.func,
}

HumanResources.defaultProps = {
  messages: {},
  classes: {},
  humans: [],
  totalExpensesToRefund: undefined,
  onSelectHuman: undefined,
  onAdd: undefined,
  onRefundClick: undefined,
  onRemove: undefined,
}

export default component({ styles })(HumanResources)
