const chargeFields = `
  id
  date
  percentage
  total
  values {
    value
    description
  }
  planned
  plannedPercentage
`

export const queryHumanResources = () => `
  humanResources {
    id
    userId
    name
    firstname

    charges {
      ${chargeFields}
    }

    revenues {
      id
      frequency
      date
      value
      planned
      dates {
        paid
      }
    }

    expenses(notRefunded: true) {
      id
      prices {
        total
      }
    }
  }
`

export const queryUsers = () => `
  users {
    id
    name
    firstName
  }
`

export const mutationAddRevenue = humanId => `
  mutation AddRevenue($revenue: InputRevenue!) {
    addRevenue(humanId: "${humanId}", input: $revenue) {
      id
      humanId
      frequency
      date
      value
    }
  }
`

export const mutationAddHuman = () => `
  mutation AddHuman($human: InputHumanResource!) {
    addHumanResource(input: $human) {
      id
      name
      firstname

      revenues {
        id
      }
    }
  }
`

export const mutationPayRevenue = () => `
  mutation PayRevenue($revenue: InputPayRevenue!) {
    payRevenue(input: $revenue) {
      id
      value
      planned
      dates {
        paid
      }
    }
  }
`

export const mutationRefundExpenses = () => `
  mutation RefundExpenses ($ids: [String!]!) {
    refundExpenses (input: $ids) {
      id
    }
  }
`

export const mutationAddCharge = humanId => `
mutation AddCharge($charge: InputCharge!) {
  addCharge(humanId: "${humanId}", input: $charge) {
    ${chargeFields}
    humanId
  }
}
`

export const mutationPayCharge = () => `
  mutation PayRevenue($charge: InputPayCharge!) {
    payCharge(input: $charge) {
      ${chargeFields}
    }
  }
`

export const mutationRemoveRevenue = () => `
  mutation RemoveRevenue($id: String!) {
    removeRevenue(input: $id)
  }
`
