import { container } from '@k-mille/ui-components'
import { fromEvent } from 'from-form-submit'
import {} from '../../humanResources.operations'
import Component from './revenue'

const mapStore = (store, { id }) => ({
  ...store.data.revenues.get(id),
  onSubmit: (event) => {
    store.dispatch({
      type: '@@ui/PAY_REVENUE',
      payload: fromEvent(event, { id }),
    })
  },
  onRemove: () => {
    store.dispatch({
      type: '@@ui>REMOVE_REVENUE',
      payload: id,
    })
  },
})

export default container({
  intl: 'revenues',
  mapStore,
})(Component)
