import { types } from 'k-ramel'

export default {
  data: {
    humanResources: types.keyValue(),
    users: types.keyValue(),
    revenues: types.keyValue(),
    charges: types.keyValue(),
  },
  ui: {
    modalId: types.string(),
    selectedHuman: types.string(),
    users: types.keyValue(),
    totalExpenseToRefund: types.number(),
  },
}
