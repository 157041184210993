import React from 'react'
import PropTypes from 'prop-types'
import { component, Modal } from '@k-mille/ui-components'
import User from './user'

const AddHuman = ({
  messages,
  users,
  onSubmit,
}) => (
  <Modal id="add">
    <h2>{messages.fromUsers}</h2>
    {users.map(user => (
      <User
        key={user.id}
        {...user}
      />
    ))}
    {users.length === 0 && (
      <div>
        {messages.noMoreUser}
      </div>
    )}

    <h2>{messages.fromScratch}</h2>
    <form onSubmit={onSubmit}>
      <input
        type="string"
        name="firstname"
        placeholder={messages.firstname}
      />
      <input
        type="string"
        name="name"
        placeholder={messages.name}
      />
      <input
        type="email"
        name="email"
        placeholder={messages.email}
      />
      <input
        type="date"
        name="from"
      />

      <button type="submit">
        {messages.add}
      </button>
    </form>
  </Modal>
)

AddHuman.propTypes = {
  messages: PropTypes.object,
  users: PropTypes.array,
  onSubmit: PropTypes.func,
}

AddHuman.defaultProps = {
  messages: {},
  users: [],
  onSubmit: undefined,
}

export default component()(AddHuman)
