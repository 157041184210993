import { container } from '@k-mille/ui-components'
import listeners from './humanResources.listeners'
import Component from './humanResources'

const mapStore = store => ({
  humans: store.data.humanResources.getAsArray(),
  revenues: (store.data.humanResources.get(store.ui.selectedHuman.get()) || { }).revenues,
  totalExpensesToRefund: store.ui.totalExpenseToRefund.get(),
  onSelectHuman: (e) => {
    store.dispatch({ type: '@@ui>HUMAN_SELECTED', payload: e.target.value })
  },
  onAdd: () => {
    store.ui.modalId.set('add')
  },
  onRefundClick: () => {
    store.dispatch('@@ui>REFUND_CLICKED')
  },
  onRemove: () => {
    alert('TODO:') // eslint-disable-line no-alert, no-undef
  },
})

export default container({
  name: 'main',
  intl: 'humanResources',
  isScreen: true,
  mapStore,
  listeners,
})(Component)
