import { container } from '@k-mille/ui-components'
import Component from './user'

const mapStore = (store, { id }) => ({
  onClick: () => {
    store.dispatch({ type: '@@ui/ADD_HUMAN', payload: { userId: id } })
  },
})

export default container({
  intl: 'addHumanResource',
  mapStore,
})(Component)
