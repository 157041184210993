import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'

const HumanItem = ({
  messages,
  id,
}) => (
  <option value={id}>
    {messages.fullname}
  </option>
)

HumanItem.propTypes = {
  messages: PropTypes.object,
  id: PropTypes.string,
}

HumanItem.defaultProps = {
  messages: {},
  id: undefined,
}

export default component()(HumanItem)
