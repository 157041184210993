import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import Revenue from './revenue'

const Revenues = ({
  className,
  messages,
  revenues,
  onAdd,
}) => (
  <div className={className}>
    <form onSubmit={onAdd}>
      <input name="date" type="date" />
      <select name="frequency">
        <option value="MONTH">{messages.month}</option>
        <option value="YEAR">{messages.year}</option>
      </select>
      <input name="value" type="number" />

      <button type="submit">
        {messages.add}
      </button>
    </form>
    <table>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>{messages.titles.date}</th>
          <th>{messages.titles.planned}</th>
          <th>{messages.titles.value}</th>
        </tr>
      </thead>
      <tbody>
        {revenues.map(id => (
          <Revenue
            key={id}
            id={id}
          />
        ))}
      </tbody>
    </table>
  </div>
)

Revenues.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.object,
  revenues: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func,
}

Revenues.defaultProps = {
  className: undefined,
  messages: {},
  revenues: [],
  onAdd: undefined,
}

export default component()(Revenues)
