import { container } from '@k-mille/ui-components'
import { fromEvent } from 'from-form-submit'
import Component from './revenues'

const mapStore = store => ({
  revenues: (store.data.humanResources.get(store.ui.selectedHuman.get()) || { }).revenues,
  onAdd: (event) => {
    store.dispatch({ type: '@@ui/ADD_REVENUE', payload: fromEvent(event) })
  },
})

export default container({
  intl: 'revenues',
  mapStore,
})(Component)
