import { when } from 'k-ramel'
import {
  addCharge,
  addHuman,
  addRevenue,
  createCharge,
  createHuman,
  createRevenue,
  filterUsers,
  load,
  payCharge,
  payRevenue,
  refundExpenses,
  set,
  setChargeAsPaid,
  setExpenses,
  setRevenueAsPaid,
  setTotalExpenseToRefund,
  setUsers,
  setSelectedHuman,
  removeRevenue,
} from './humanResources.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>main')(load),
  when('@@graphql/humanResources>ENDED')(set),
  when('@@graphql/users>ENDED')(setUsers),
  when('@@graphql/expenses>ENDED')(setExpenses),
  when('@@graphql/humanResources,users>DONE')(filterUsers),
  when('@@ui>HUMAN_SELECTED')(setSelectedHuman),
  when('@@krf/SET>UI>SELECTEDHUMAN')(setTotalExpenseToRefund),
  when('@@ui/ADD_REVENUE')(createRevenue),
  when('@@graphql/addRevenue>ENDED')(addRevenue),
  when('@@ui/ADD_HUMAN')(createHuman),
  when('@@graphql/addHumanResource>ENDED')(addHuman),
  when('@@ui>REFUND_CLICKED')(refundExpenses),
  when('@@graphql/refundExpenses>ENDED')(load),
  when('@@ui/PAY_REVENUE')(payRevenue),
  when('@@graphql/payRevenue>ENDED')(setRevenueAsPaid),
  when('@@ui/ADD_CHARGE')(createCharge),
  when('@@graphql/addCharge>ENDED')(addCharge),
  when('@@ui/PAY_CHARGE')(payCharge),
  when('@@graphql/payCharge>ENDED')(setChargeAsPaid),
  when('@@ui>REMOVE_REVENUE')(removeRevenue),
]
